import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { GlobleService } from './globle.service';

@Injectable()
export class AdminService {
    rootUrl: string = this.globalService.apiUrl;
    header: any = this.globalService.getHeader();

    constructor(private http: HttpClient, private globalService: GlobleService) {}

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    /**
     * Get all phone number
     * @param phone
     */
    modifyProfile(phone, obj) {
        return this.http.put(`${this.rootUrl}/seatel/${phone}/modify`, obj, {
            headers: this.header
        });
    }

    /**
     * Remove link phone number
     * @param mainPhone
     * @param phone
     */
    unlinkPhoneNumber(mainPhone, phone) {
        return this.http.put(
            this.rootUrl + '/users/' + mainPhone + '/unlinked-phone-number',
            {
                unlinkedPhoneNumbers: [phone]
            },
            { headers: this.header }
        );
    }

    /**
     * Get all phone number
     * @param phone
     */
    getProfiles(phone) {
        return this.http.get(`${this.rootUrl}/v2/seatel/profiles?phoneNumber=${phone}`, {
            headers: this.header
        });
    }

    /**
     * Request code by phone number
     * @param body
     */
    requestCode(body) {
        return this.http.post(`${this.rootUrl}/v2/seatel/request-code`, body, {
            headers: this.header
        });
    }

    /**
     * Confirm code to success
     * @param body
     */
    confirmCode(body) {
        return this.http.post(`${this.rootUrl}/v2/seatel/confirm-code`, body, {
            headers: this.header
        });
    }

    /**
     * Get profile data
     * @param phone
     */
    getProfile(phone) {
        return this.http.get(`${this.rootUrl}/v2/seatel/profile?phoneNumber=${phone}`, {
            headers: this.header
        });
    }

    /**
     * Update profile image
     * @param phone
     * @param body
     */
    UpdatePrfileImage(phone, body) {
        return this.http.put(`${this.rootUrl}/v2/seatel/${phone}/profile`, body, {
            headers: this.header
        });
    }

    /**
     * Get subscription
     * @param phone
     */
    getSubscription(phone) {
        return this.http.get(`${this.rootUrl}/seatel/subscriptions?phoneNumber=${phone}`, {
            headers: this.header
        });
    }

    getNotification(phone, url): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/v2/notifications?phoneNumber=${phone}` + url, {
                headers: this.header
            })
            .pipe(catchError(this.handleError));
    }
    getBageNotification(phone): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/users/${phone}/badges`, {
                headers: this.header
            })
            .pipe(catchError(this.handleError));
    }
    getMainBilling(phone, dayDate): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/billings?phoneNumber=${phone}` + dayDate, {
                headers: this.header
            })
            .pipe(catchError(this.handleError));
    }
    getBillingInternetDetail(phone, dayDate): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/billings/internet?phoneNumber=${phone}` + dayDate, {
                headers: this.header
            })
            .pipe(catchError(this.handleError));
    }
    getBillingVoiceDetail(phone, dayDate): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/billings/voice?phoneNumber=${phone}` + dayDate, {
                headers: this.header
            })
            .pipe(catchError(this.handleError));
    }
    getBillingSMSDetail(phone, dayDate): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/billings/sms?phoneNumber=${phone}` + dayDate, {
                headers: this.header
            })
            .pipe(catchError(this.handleError));
    }

    getBillingPackages(phone, dates): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/billings/packages?phoneNumber=${phone}` + dates, {
                headers: this.header
            })
            .pipe(catchError(this.handleError));
    }
    getPDFVoice(phone, dayDate): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/billings/pdf/voice?phoneNumber=${phone}` + dayDate, {
                headers: this.header
            })
            .pipe(catchError(this.handleError));
    }
    getPDFInternet(phone, dayDate): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/billings/pdf/internet?phoneNumber=${phone}` + dayDate, {
                headers: this.header,
                responseType: 'json'
            })
            .pipe(catchError(this.handleError));
    }
    getPDFSms(phone, dayDate): Observable<any> {
        return this.http
            .get(`${this.rootUrl}/billings/pdf/sms?phoneNumber=${phone}` + dayDate, {
                headers: this.header,
                responseType: 'json'
            })
            .pipe(catchError(this.handleError));
    }

    /**
     * Get dashboard data
     * @param phone
     */
    getMain(phone) {
        return this.http.get(`${this.rootUrl}/seatel/main?phoneNumber=${phone}`, {
            headers: this.header
        });
    }
    getPromotionDraw(code, body) {
        return this.http.post(this.rootUrl + '/promotions/' + code + '/draw', body, { headers: this.header });
    }
    getPromotionItem(drawId, body) {
        return this.http.put(`${this.rootUrl}/promotions/items/${drawId}`, body, {
            headers: this.header
        });
    }
    /**
     * Get order data
     * @param phone
     */
    getOrder(phone) {
        return this.http.get(`${this.rootUrl}/orders?phoneNumber=${phone}&limit=100`, {
            headers: this.header
        });
    }

    /**
     * Get order data
     * @param phone
     */
    getOrderHistory(phone) {
        return this.http.get(`${this.rootUrl}/orders/history?phoneNumber=${phone}&limit=100`, {
            headers: this.header
        });
    }

    /**
     * Confirm order received
     * @param orderId
     */
    confirmOrder(orderId) {
        return this.http.put(
            `${this.rootUrl}/orders/${orderId}`,
            {
                orderServiceStatus: 'delivered'
            },
            {
                headers: this.header
            }
        );
    }

    /**
     * Delete order delivered
     * @param id
     */
    deletedOrder(id) {
        return this.http.delete(this.rootUrl + '/orders/' + id, {
            headers: this.header
        });
    }

    /**
     * Change password in profile
     * @param obj
     */
    changePwd(obj) {
        return this.http.post(`${this.rootUrl}/seatel/change-password`, obj, {
            headers: this.header
        });
    }

    /**
     * Submit top up
     * @param obj
     */
    topUp(obj) {
        return this.http.post(`${this.rootUrl}/seatel/top-up`, obj, {
            headers: this.header
        });
    }

		getTopUpAmount() {
			return this.http.get(`${this.rootUrl}/top-up/amount-list`, {headers: this.header});
		}

    /**
     * Verify pin code in top up
     * @param obj
     */
    verifyPin(obj) {
        return this.http.post(`${this.rootUrl}/seatel/verify-pin`, obj, {
            headers: this.header
        });
    }

    /**
     * Verify phone number in top up
     * @param obj
     */
    verifyPhone(obj) {
        return this.http.put(`${this.rootUrl}/seatel/verify-phone-number`, obj, {
            headers: this.header
        });
    }

    requestAbaCard(url): Observable<any> {
        return this.http.get(`${url}`, { headers: this.header }).pipe(catchError(this.handleError));
    }

		postWingToken(url: string, body: any) {
			return this.http.post(`${url}`, body, {headers: this.header}).pipe(catchError(this.handleError));
		}
}
