import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent, SafePipe } from './home-page/home-page.component';
import { ContactComponent } from './contact/contact.component';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { LayoutsComponent } from './layouts.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgwWowModule } from 'ngx-wow';

import { ProductsServicesModule } from './products-services/products-services.module';
import { SupportModule } from './support/support.module';
import { AboutModule } from './about/about.module';
import { BusinessModule } from './business/business.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SearchComponent, HighlightPipe } from './search/search.component';
import { ToImageUrlPipe } from '../to-image-url.pipe';

import { ReactiveFormsModule } from '@angular/forms';
import { ProductServiceComponent } from './products-services/product-service/product-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqLayoutComponent } from './faq-layout/faq-layout.component';
import { FaqModule } from './faq-layout/faq.module';
import { TopUpComponent } from './top-up/top-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CartModule } from './cart/cart.module';
import { TopUpNumberComponent } from './top-up-number/top-up-number.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { SelfRegistrationProfileComponent } from './self-registration-profile/self-registration-profile.component';
import { FormatTimePipe } from '../minute-seconds.pipe';
import { AddOnsComponent } from './add-ons/add-ons.component';
import { AddOnsModule } from './add-ons/add-ons.module';
import { EventsComponent } from './events/events.component';
import { CodeNumbersPipe } from '../shared/pipes/code-numbers.pipe';
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    imports: [
        CommonModule,
        LayoutsRoutingModule,
        HttpClientModule,
        SupportModule,
        AboutModule,
        BusinessModule,
        CartModule,
        NgwWowModule,
        ProductsServicesModule,
        FormsModule,
        ReactiveFormsModule,
        FaqModule,
        AddOnsModule,
        SharedPipesModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        LayoutsComponent,
        HomePageComponent,
        ContactComponent,
        LoginComponent,
        RegisterComponent,
        SearchComponent,
        ToImageUrlPipe,
        SafePipe,
        HighlightPipe,
        PrivacyPolicyComponent,
        TopUpComponent,
        ForgotPasswordComponent,
        TopUpNumberComponent,
        TermsAndConditionsComponent,
        SelfRegistrationProfileComponent,
        EventsComponent,
        FormatTimePipe,
        CodeNumbersPipe
    ],
    providers: []
})
export class LayoutsModule {}
